import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { PackageMetadata } from '@happeo/docs-tools';
import pkg from "../package.json";
import { Tooltip } from '../src/index';
import { TextDelta, BodyUI } from "@happeouikit/typography";
import { success, navy, warn } from "@happeouikit/colors";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "tooltip"
    }}>{`Tooltip`}</h1>
    <PackageMetadata pkg={pkg} mdxType="PackageMetadata" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic Usage`}</h2>
    <h4 {...{
      "id": "pass-the-data-tip-property-to-the-elements-you-want-to-show-the-tooltip"
    }}>{`Pass the `}<inlineCode parentName="h4">{`data-tip`}</inlineCode>{` property to the elements you want to show the tooltip.`}</h4>
    <Playground __position={1} __code={'<div>\n  <span data-tip>Hover me</span>\n</div>\n<Tooltip content=\"This is a tooltip\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Tooltip,
      TextDelta,
      BodyUI,
      success,
      navy,
      warn,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <div>
        <span data-tip>Hover me</span>
    </div>
    <Tooltip content='This is a tooltip' mdxType="Tooltip" />
    </Playground>
    <h4 {...{
      "id": "pass-the-small-property-if-you-want-to-show-a-smaller-tooltip-without-the-indicating-arrow"
    }}>{`Pass the `}<inlineCode parentName="h4">{`small`}</inlineCode>{` property if you want to show a smaller tooltip, without the indicating arrow.`}</h4>
    <Playground __position={2} __code={'<div>\n  <span data-tip data-for=\"tooltip-small\">\n    Hover me for small tooltip\n  </span>\n</div>\n<Tooltip id=\"tooltip-small\" small content=\"This is a small tooltip\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Tooltip,
      TextDelta,
      BodyUI,
      success,
      navy,
      warn,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <div>
        <span data-tip data-for='tooltip-small'>Hover me for  small tooltip</span>
    </div>
    <Tooltip id='tooltip-small' small content='This is a small tooltip' mdxType="Tooltip" />
    </Playground>
    <h4 {...{
      "id": "use-the-data-for-and-id-props-to-display-the-tooltips-to-only-specific-elements"
    }}>{`Use the `}<inlineCode parentName="h4">{`data-for`}</inlineCode>{` and `}<inlineCode parentName="h4">{`id`}</inlineCode>{` props to display the tooltips to only specific elements.`}</h4>
    <Playground __position={3} __code={'<div>\n  <span data-tip data-for=\"tooltip1\">\n    This has a special tooltip\n  </span>\n</div>\n<Tooltip id=\"tooltip1\" content=\"Tooltip with an id\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Tooltip,
      TextDelta,
      BodyUI,
      success,
      navy,
      warn,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <div>
        <span data-tip data-for='tooltip1'>This has a special tooltip</span>
    </div>
    <Tooltip id='tooltip1' content='Tooltip with an id' mdxType="Tooltip" />
    </Playground>
    <h2 {...{
      "id": "four-ways-to-create-the-content-of-tooltips"
    }}>{`Four ways to create the content of tooltips.`}</h2>
    <h4 {...{
      "id": "use-content-props"
    }}>{`Use `}<inlineCode parentName="h4">{`content`}</inlineCode>{` props`}</h4>
    <Playground __position={4} __code={'<div>\n  <span data-tip data-for=\"tooltip2\">\n    Hover me\n  </span>\n</div>\n<Tooltip id=\"tooltip2\" content=\"I have content props\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Tooltip,
      TextDelta,
      BodyUI,
      success,
      navy,
      warn,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <div>
        <span data-tip data-for='tooltip2'>Hover me</span>
    </div>
    <Tooltip id='tooltip2' content='I have content props' mdxType="Tooltip" />
    </Playground>
    <h4 {...{
      "id": "or-pass-the-message-to-data-tip"
    }}>{`or pass the message to `}<inlineCode parentName="h4">{`data-tip`}</inlineCode></h4>
    <Playground __position={5} __code={'<div>\n  <span data-tip=\"Message from data-tip\" data-for=\"tooltip3\">\n    Hover me\n  </span>\n</div>\n<Tooltip id=\"tooltip3\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Tooltip,
      TextDelta,
      BodyUI,
      success,
      navy,
      warn,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <div>
        <span data-tip='Message from data-tip' data-for='tooltip3'>Hover me</span>
    </div>
    <Tooltip id='tooltip3' mdxType="Tooltip" />
    </Playground>
    <h4 {...{
      "id": "or-wrap-any-components"
    }}>{`or wrap any components`}</h4>
    <Playground __position={6} __code={'<div>\n  <span data-tip data-for=\"tooltip4\">\n    Hover me\n  </span>\n</div>\n<Tooltip id=\"tooltip4\">\n  <TextDelta bold color=\"#fff\">\n    Custom component\n  </TextDelta>\n</Tooltip>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Tooltip,
      TextDelta,
      BodyUI,
      success,
      navy,
      warn,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <div>
        <span data-tip data-for='tooltip4'>Hover me</span>
    </div>
    <Tooltip id='tooltip4' mdxType="Tooltip">
        <TextDelta bold color='#fff' mdxType="TextDelta">Custom component</TextDelta>
    </Tooltip>
    </Playground>
    <h4 {...{
      "id": "or-use-getcontent-getcontent-receives-the-message-from-data-tip-as-the-parameter"
    }}>{`or use `}<inlineCode parentName="h4">{`getContent`}</inlineCode>{`. `}<inlineCode parentName="h4">{`getContent`}</inlineCode>{` receives the message from `}<inlineCode parentName="h4">{`data-tip`}</inlineCode>{` as the parameter.`}</h4>
    <p>{`Return a component or string.`}</p>
    <Playground __position={7} __code={'<div>\n  <span data-tip=\"extended\" data-for=\"tooltip5\">\n    Hover me\n  </span>\n</div>\n<Tooltip\n  id=\"tooltip5\"\n  getContent={dataTip => (\n    <BodyUI color=\"#fff\">This message is {dataTip}</BodyUI>\n  )}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Tooltip,
      TextDelta,
      BodyUI,
      success,
      navy,
      warn,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <div>
        <span data-tip='extended' data-for='tooltip5'>Hover me</span>
    </div>
    <Tooltip id='tooltip5' getContent={dataTip => <BodyUI color='#fff' mdxType="BodyUI">This message is {dataTip}</BodyUI>} mdxType="Tooltip" />
    </Playground>
    <h2 {...{
      "id": "advance-features"
    }}>{`Advance features`}</h2>
    <h4 {...{
      "id": "you-can-set-the-color-of-tooltips-using-background-property-default-is-gray01-text-color-can-be-set-with-text-property-default-is-white-the-place-property-is-used-to-control-the-position-of-tooltips"
    }}>{`You can set the color of tooltips using `}<inlineCode parentName="h4">{`background`}</inlineCode>{` property, default is `}<inlineCode parentName="h4">{`gray01`}</inlineCode>{`. Text color can be set with `}<inlineCode parentName="h4">{`text`}</inlineCode>{` property, default is `}<inlineCode parentName="h4">{`white`}</inlineCode>{`. The `}<inlineCode parentName="h4">{`place`}</inlineCode>{` property is used to control the position of tooltips.`}</h4>
    <Playground __position={8} __code={'<div>\n  <span data-tip data-for=\"tooltip6\" id=\"id1\">\n    Hover me\n  </span>\n</div>\n<Tooltip\n  id=\"tooltip6\"\n  content=\"Fancy tooltip\"\n  background={navy}\n  text={warn}\n  place=\"right\"\n  effect=\"float\"\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Tooltip,
      TextDelta,
      BodyUI,
      success,
      navy,
      warn,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <div>
        <span data-tip data-for='tooltip6' id='id1'>Hover me</span>
    </div>
    <Tooltip id='tooltip6' content='Fancy tooltip' background={navy} text={warn} place='right' effect='float' mdxType="Tooltip" />
    </Playground>
    <h4 {...{
      "id": "delayshow-and-delayhide-props-controls-the-display-duration-of-tooltips"
    }}><inlineCode parentName="h4">{`delayShow`}</inlineCode>{` and `}<inlineCode parentName="h4">{`delayHide`}</inlineCode>{` props controls the display duration of tooltips.`}</h4>
    <Playground __position={9} __code={'<div>\n  <span data-tip data-for=\"tooltip7\">\n    Hover\n  </span>\n</div>\n<Tooltip\n  id=\"tooltip7\"\n  delayHide={1000}\n  content=\"This tooltip will stay for 1 second\"\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Tooltip,
      TextDelta,
      BodyUI,
      success,
      navy,
      warn,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <div>
        <span data-tip data-for='tooltip7'>Hover</span>
    </div>
    <Tooltip id='tooltip7' delayHide={1000} content='This tooltip will stay for 1 second' mdxType="Tooltip" />
    </Playground>
    <Props of={Tooltip} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      